import { computed, readonly, ref, type App, type Plugin } from 'vue';
import { merge } from 'lodash-es';
import VueI18Next from 'i18next-vue';
import i18next from 'i18next';
import ResourcesToBackend from 'i18next-resources-to-backend';
import {
    availableLocales,
    defaultNamespace,
    defaultLocale,
    getLanguageCodeFromLocale,
    type Locale,
} from '@/utils/langUtils';
import { setRouteLang } from '@/router/route';
import dayjs from '@/plugins/dayjs';
import type { EnumToUnion } from '@/types/misc';

const useSchoolTranslations = window.sdWindow.user?.is_pupil ?? false;

const currentLocale = ref<EnumToUnion<Locale>>((document.documentElement.lang as EnumToUnion<Locale>) ?? defaultLocale);

/**
 * Lazy loads a i18n resource based on the provided parameters.
 * Fallback handling is provided to not break the application rendering.
 */
function loadResource(language: string, namespace: string, flavor?: string) {
    const file = flavor
        ? import(`../lang/${language}/${flavor}/${namespace}.json`)
        : import(`../lang/${language}/${namespace}.json`);
    return file.catch(() => ({ default: {} })).then((_) => _.default as object);
}

i18next
    .use(
        /*
         * Instead of requesting the i18n files from the server,
         * this plugin will integrate the i18n files into the frontend bundle.
         * This allows to have HMR, versioning support and more customized file handling.
         */
        ResourcesToBackend(async (lng: string, ns: string) => {
            const normal = loadResource(lng, ns);
            if (!useSchoolTranslations) return normal;

            const school = loadResource(lng, ns, 'school');
            // we merge the school flavor into the normal translations and overwrite them.
            return merge(await normal, await school);
        }),
    )
    .init({
        lng: currentLocale.value,
        supportedLngs: availableLocales,
        fallbackLng: defaultLocale,
        ns: [defaultNamespace],
        defaultNS: defaultNamespace,
        interpolation: {
            escapeValue: false,
        },
        parseMissingKeyHandler(): string {
            return '';
        },
    })
    .then(() => {
        i18next.options.interpolation!.defaultVariables = {
            institution: useSchoolTranslations ? 'school' : 'uni',
        };
    });

/**
 * Changes language and updates dependencies
 */
export async function changeLanguage(locale: EnumToUnion<Locale>) {
    currentLocale.value = locale;
    document.documentElement.lang = locale;
    dayjs.locale(locale);
    setRouteLang(getLanguageCodeFromLocale(locale));
    return i18next.changeLanguage(locale);
}

export const i18n = i18next;

// those values are our current locale source of truth
// the states in our pinia or vuex store are just replications
export const locale = readonly(currentLocale);
export const languageCode = computed(() => getLanguageCodeFromLocale(currentLocale.value));

export default {
    install(app: App) {
        app.use(VueI18Next, {
            i18next,
            slotStart: '{{',
            slotEnd: '}}',
            legacyI18nOptionsSupport: true,
        });
    },
} as Plugin;
