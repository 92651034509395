import { shallowReactive, toRefs, watch } from "vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { dataGuard, type RouteData } from "@/router/guards/dataGuard";

/**
 * Provides the data specified with the dataGuard.
 * And it registers the dataGuard for the same data getters for on-page route changes.
 * 
 * If you use tanstack query, you don't need to use the data provided by this composable,
 * but should still call it to handle on-page route changes.
 */
export function useRouteData<T extends RouteData<unknown>>() {
    const route = useRoute();
    if (!route.meta.data?.data) throw new Error('Route does not have data provided by `dataGuard`');

    // register dataGuard for any on-page route changes
    onBeforeRouteUpdate(dataGuard(route.meta.data.getter, route.meta.data.errorHandler));

    const data = shallowReactive(route.meta.data.data);

    watch(() => route.meta.data?.data, (val) => {
        Object.assign(data, val);
    });

    return toRefs(data as T);
}
