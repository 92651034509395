import dayjs from 'dayjs';
import { useQuery } from '@tanstack/vue-query';
import { getSemesters } from '@/api/gateway/legacy-api/study';
import { fetchPage } from '@/api/builder';
import { displaySemestersList } from '@/utils/semesterUtils';
import { QUERY_KEYS, persister } from '@/plugins/vue-query';

export function useSemesters() {
    return useQuery({
        queryKey: [QUERY_KEYS.SEMESTERS],
        queryFn: getSemesters,
        select: (data) => displaySemestersList(data.data),
        staleTime: dayjs.duration(1, 'day').asSeconds(),
        persister,
    });
}

export const getBuilderPageContentQuery = (urlPath: string) => ({
    queryKey: [QUERY_KEYS.BUILDER_CONTENT, urlPath],
    queryFn: () => fetchPage(urlPath),
    staleTime: dayjs.duration(1, 'day').asSeconds(),
});

export function useBuilderPageContent(urlPath: string) {
    return useQuery(getBuilderPageContentQuery(urlPath));
}
