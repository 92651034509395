import type { AxiosError } from 'axios';
import axios from 'axios';
import { once } from 'lodash-es';
import { i18n } from '@/plugins/i18n';
import { HTTP_STATUS } from '@/utils/networkUtils';
import { notifyError } from '@/components/FlashMessages';
import type { AnyApiErrorResponse } from '@/api/types';
import { EventBus, EventBusEvent } from '@/plugins/eventBus';

const ErrorKey = Object.freeze({
    NetworkError: 'network-error',
    Offline: 'offline',
    Maintenance: 'maintenance',
});

const AssetLoadErrorMessages = Object.freeze([
    'error loading dynamically imported module', // firefox
    'Failed to fetch dynamically imported module', // chrome
    'Failed to load resource', // safari
]);

/**
 * Determines the error key for some error types to easily group them.
 */
function getErrorKey(error: AxiosError): string | undefined {
    switch (error.response?.status) {
        case HTTP_STATUS.NETWORK_ERROR:
            return ErrorKey.NetworkError;
        case HTTP_STATUS.MAINTENANCE:
            return ErrorKey.Maintenance;
        default:
            return undefined;
    }
}

function isAssetLoadError(error: unknown): boolean {
    return (
        error instanceof Error &&
        AssetLoadErrorMessages.some((_) => error.message.includes(_)) &&
        ['/assets/', '/resources/'].some((_) => error.message.includes(_))
    );
}

/**
 * Indicates whether we should report an error with given http status to our error logging
 */
function shouldPropagateHttpStatus(error: AxiosError): boolean {
    switch (error?.response?.status) {
        // network errors have a network status of 'null'
        case HTTP_STATUS.NETWORK_ERROR:
        case HTTP_STATUS.TOO_MANY_REQUESTS:
        case HTTP_STATUS.UPGRADE_REQUIRED:
            return false;
        default:
            return true;
    }
}

/**
 * Inform the user about an error
 */
function reportError(error: AxiosError<AnyApiErrorResponse>): void {
    const data = error.response?.data;

    if (error?.response?.status === HTTP_STATUS.UPGRADE_REQUIRED) {
        EventBus.emit(EventBusEvent.PROMPT_UPDATE);
        return;
    }

    // error is more specific for request validations, so we should process that first
    // Otherwise, error and message are somewhat used interchangeably
    const content =
        (data &&
            typeof data === 'object' &&
            (('error' in data &&
                (typeof data.error === 'object'
                    ? // new error format
                      data.error.error_user_msg || data.error.message
                    : // old  error message as fallback
                      data.error)) ||
                ('message' in data && data.message) ||
                ('msg' in data && data.msg))) ||
        i18n.t('errors.unexpectedError');

    notifyError(content, { key: getErrorKey(error) });
}

/**
 * Handles unhandled axios errors and invokes flash messages.
 * @param notifyUser - whether the user should receive a flash message for the error
 * @returns true if error was handled and doesn't need to be processed further
 */
export function handleAxiosError(error: unknown, notifyUser = false): boolean {
    if (!axios.isAxiosError(error)) return false;

    // don't further process cancelled/aborted requests
    if (axios.isCancel(error)) return true;

    if ((error as AxiosError).ignore) return true;

    if (notifyUser || !(error as AxiosError).mute) {
        reportError(error);
    }

    return !shouldPropagateHttpStatus(error);
}

/**
 * Handles asset load errors and reprots that to the user
 * @returns true if error was handled and doesn't need to be processed further
 */
export function handleAssetError(error: unknown): boolean {
    if (isAssetLoadError(error)) {
        if (navigator.onLine) {
            EventBus.emit(EventBusEvent.PROMPT_UPDATE);
        } else {
            notifyError(i18n.t('errors.offlineError'), { key: ErrorKey.Offline });
        }
        return true;
    }
    return false;
}

/**
 * Registers global axios error handler that catches all unhandled responses
 */
export const registerGlobalErrorHandler = once(() => {
    const originalHandler = window.onunhandledrejection;

    window.onunhandledrejection = (event) => {
        if (handleAxiosError(event.reason, true) || handleAssetError(event.reason)) {
            event.stopImmediatePropagation();
            event.preventDefault();
        }

        if (event.bubbles && originalHandler) {
            originalHandler.call(window, event);
        }
    };

    // eslint-disable-next-line tipsi/remove-event-listener
    window.addEventListener('vite:preloadError', (event) => {
        // vite:preloadError is emitted when a resource fails to load and the user is online
        // for all other cases, the onunhandledrejection event handles the error

        EventBus.emit(EventBusEvent.PROMPT_UPDATE);

        event.stopImmediatePropagation();
        event.preventDefault();
    });
});
