<template>
    <Content
        :model="model"
        :content="content"
        :api-key="apiKey"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { BuilderContent} from '@builder.io/sdk-vue';
import { Content } from '@builder.io/sdk-vue';
import { useRouteData } from '@/composables/routeData';
import { keepUrlGuard } from '@/router/guards';

export default defineComponent({
    name: 'BuilderPage',
    components: {
        Content,
    },
    beforeRouteUpdate: keepUrlGuard,
    beforeRouteEnter: keepUrlGuard,
    setup() {
        const [content] = useRouteData<[BuilderContent]>();
        const apiKey = import.meta.env.MIX_BUILDER_IO_API_KEY;
        const model = import.meta.env.MIX_BUILDER_IO_PAGE_MODEL;

        return {
            content,
            apiKey,
            model,
        };
    },
});
</script>
