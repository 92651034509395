import { joinGroup, leaveGroup, getFeed, getFaq } from '@/api/backend/group';
import {
    deletePost,
    editPost,
    reportPost,
    togglePinnedPost,
    createComment,
    deleteComment,
    editComment,
    reportComment,
    toggleBestComment,
    getComments,
} from '@/api/backend/post';
import { find } from 'lodash-es';
import { injectXandrAds } from '@/utils/xandrUtils';

const types = {
    ADD_OR_UPDATE_POST_IN_FEED: 'ADD_OR_UPDATE_POST_IN_FEED',
    ADD_OR_UPDATE_COMMENT_IN_POST: 'ADD_OR_UPDATE_COMMENT_IN_POST',
    PROPAGATE_IDENTITY_CHANGE: 'PROPAGATE_IDENTITY_CHANGE',
    REMOVE_COMMENT_FROM_POST: 'REMOVE_COMMENT_FROM_POST',
    REMOVE_POST_FROM_FEED: 'REMOVE_POST_FROM_FEED',
    SET_GROUP_DATA: 'SET_GROUP_DATA',
    SET_REPORTED_POST: 'SET_REPORTED_POST',
    SET_REPORTED_COMMENT: 'SET_REPORTED_COMMENT',
    TOGGLE_BEST_COMMENT: 'TOGGLE_BEST_COMMENT',
    TOGGLE_FAQ_QUESTION: 'TOGGLE_FAQ_QUESTION',
    UPDATE_CAN_LOAD_MORE_FEED: 'UPDATE_CAN_LOAD_MORE_FEED',
    SET_FAQ: 'SET_FAQ',
    SET_FAQ_PROGRESS: 'SET_FAQ_PROGRESS',
    SET_FAQ_ERROR: 'SET_FAQ_ERROR',
    UPDATE_FEED_LIST: 'UPDATE_FEED_LIST',
    UPDATE_FEED_PROGRESS: 'UPDATE_FEED_PROGRESS',
    UPDATE_JOIN_PROGRESS: 'UPDATE_JOIN_PROGRESS',
    UPDATE_LEAVE_PROGRESS: 'UPDATE_LEAVE_PROGRESS',
    UPDATE_MEMBERS: 'UPDATE_MEMBERS',

    UPDATE_POST_COMMENTS: 'UPDATE_POST_COMMENTS',
};

const state = {
    group: {},
    joinProgress: false,
    leaveProgress: false,
    suggestProgress: false,
    feed: {
        list: [],
        progress: false,
        canLoadMore: true,
    },
    faq: null,
    faqProgress: true,
    faqError: false,
};

const mutations = {
    [types.SET_GROUP_DATA](state, payload) {
        state.group = payload;
    },
    [types.SET_REPORTED_POST](state, postId) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        post.postDetails.isReported = true;
        post.postDetails.userReport = true;
    },
    [types.SET_REPORTED_COMMENT](state, { postId, commentId }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        const comment = post.comments.find((item) => {
            return item.id === commentId;
        });
        comment.isReported = true;
        comment.userReport = true;
    },
    [types.UPDATE_JOIN_PROGRESS](state, payload) {
        state.joinProgress = payload;
    },
    [types.UPDATE_LEAVE_PROGRESS](state, payload) {
        state.leaveProgress = payload;
    },
    [types.UPDATE_FEED_LIST](state, payload) {
        state.feed.list.push(...payload);
    },
    [types.UPDATE_FEED_PROGRESS](state, payload) {
        state.feed.progress = payload;
    },
    [types.UPDATE_CAN_LOAD_MORE_FEED](state, payload) {
        state.feed.canLoadMore = payload;
    },
    [types.UPDATE_POST_COMMENTS](state, { postId, comments = {} }) {
        const postIndex = state.feed.list.findIndex((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });

        if (postIndex === -1) {
            throw new Error('Post not found');
        }

        state.feed.list[postIndex].comments = comments;
    },
    [types.UPDATE_MEMBERS](state, members) {
        state.group.members = members;
    },
    [types.UPDATE_PAGINATION](state, payload) {
        state.groups.pagination = payload;
    },
    [types.SET_FAQ_PROGRESS](state, payload) {
        state.faqProgress = payload;
    },
    [types.SET_FAQ_ERROR](state, payload) {
        state.faqError = payload;
    },
    [types.SET_FAQ](state, payload) {
        state.faq = payload;
    },
    [types.TOGGLE_FAQ_QUESTION](state, questionId) {
        const updatedQuestion = find(state.faq.questions, (question) => question.id === questionId);
        updatedQuestion.isOpen = !updatedQuestion.isOpen;
        state.faq.questions = [
            ...state.faq.questions.map((item) =>
                item.id !== updatedQuestion.id ? item : { ...item, ...updatedQuestion },
            ),
        ];
    },
    [types.PROPAGATE_IDENTITY_CHANGE](state, { postId, user, isAnonymous }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        if (post.isOwner) {
            if (isAnonymous) {
                post.postDetails.anonymousUser = user;
                post.postDetails.user = null;
            } else {
                post.postDetails.user = user;
                post.postDetails.anonymousUser = null;
            }
            post.postDetails.isAnonymous = isAnonymous;
        }

        post.comments.forEach((comment) => {
            /* eslint-disable no-param-reassign */
            if (comment.isOwner) {
                if (isAnonymous) {
                    comment.anonymousUser = user;
                    comment.user = null;
                } else {
                    comment.user = user;
                    comment.anonymousUser = null;
                }
                comment.isAnonymous = isAnonymous;
            }
            /* eslint-enable no-param-reassign */
        });
    },
    [types.REMOVE_COMMENT_FROM_POST](state, { commentId, postId }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        const commentIndex = post.comments.findIndex((comment) => {
            return comment.id === commentId;
        });

        if (commentIndex === -1) {
            throw new Error('Comment not found');
        }

        post.comments.splice(commentIndex, 1);
    },
    [types.ADD_OR_UPDATE_POST_IN_FEED](state, post) {
        const postIndex = state.feed.list.findIndex((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === post.postDetails.id;
        });

        // if the post exists we just replace it with its updated version
        if (postIndex !== -1) {
            /* eslint-disable no-param-reassign */
            post.comments = state.feed.list[postIndex].comments;
            post.totalComments = state.feed.list[postIndex].totalComments;
            post.isPinned = state.feed.list[postIndex].isPinned;
            /* eslint-enable no-param-reassign */
            state.feed.list[postIndex] = post;
            return;
        }

        // if it's a new post we add it to the beginning of the feed
        state.feed.list.unshift(post);
    },
    [types.ADD_OR_UPDATE_COMMENT_IN_POST](state, comment) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === comment.context.id;
        });

        const commentIndex = post.comments.findIndex((postComment) => {
            return postComment.id === comment.id;
        });

        // if the comment exists we just replace it with its updated version
        if (commentIndex !== -1) {
            post.comments[commentIndex] = comment;
            return;
        }

        // if it's a new comment we push it to the post
        post.comments.push(comment);
        post.totalComments += 1;
    },
    [types.REMOVE_POST_FROM_FEED](state, postId) {
        const postIndex = state.feed.list.findIndex((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });

        if (postIndex === -1) {
            throw new Error('Post not found');
        }

        state.feed.list.splice(postIndex, 1);
    },
    [types.TOGGLE_BEST_COMMENT](state, { commentId, postId }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });

        if (post === undefined) {
            throw new Error('Post not found, cannot toggle best comment.');
        }

        const bestComment = post.comments.find((comment) => comment.id === commentId);

        if (bestComment === undefined) {
            throw new Error('Comment not found, cannot toggle best comment.');
        }

        post.postDetails.hasBestAnswer = !post.postDetails.hasBestAnswer;
        bestComment.isBest = !bestComment.isBest;
    },
};

const actions = {
    join({ commit, dispatch }, group) {
        commit('UPDATE_JOIN_PROGRESS', true);
        return joinGroup(group.id)
            .then((response) => {
                dispatch('user/pushGroup', response.data.data, { root: true });
                commit('UPDATE_MEMBERS', group.members + 1);
            })
            .finally(() => {
                commit('UPDATE_JOIN_PROGRESS', false);
            });
    },
    leave({ commit, dispatch }, { group }) {
        commit('UPDATE_LEAVE_PROGRESS', true);
        return leaveGroup(group.id)
            .then(() => {
                dispatch('user/popGroup', group, { root: true });
                commit('UPDATE_MEMBERS', group.members - 1);
            })
            .finally(() => commit('UPDATE_LEAVE_PROGRESS', false));
    },
    propagateIdentityChange({ commit }, { postId, user, isAnonymous }) {
        commit(types.PROPAGATE_IDENTITY_CHANGE, {
            postId,
            user,
            isAnonymous,
        });
    },
    async retrieveFeed({ commit, getters, rootGetters }, { page }) {
        commit('UPDATE_FEED_PROGRESS', true);

        const response = await getFeed(getters.group.id, page);
        const { data, meta } = response.data;

        commit(
            'UPDATE_FEED_LIST',
            injectXandrAds(data || [], rootGetters['auth/isLoggedIn'], rootGetters['ad/hasAdBlocker']),
        );
        commit('UPDATE_CAN_LOAD_MORE_FEED', getters.feedList.length < meta.total);
        commit('UPDATE_FEED_PROGRESS', false);
    },
    retrieveFaq({ commit }, { id }) {
        commit(types.SET_FAQ_PROGRESS, true);

        return getFaq(id)
            .then((response) => commit(types.SET_FAQ, response.data?.data))
            .catch(() => commit(types.SET_FAQ_ERROR, true))
            .finally(() => commit(types.SET_FAQ_PROGRESS, false));
    },
    async loadPostComments({ commit }, { postId }) {
        const response = await getComments('group', postId);

        commit(types.UPDATE_POST_COMMENTS, {
            postId,
            comments: response.data.comments,
        });
    },
    setGroupData({ commit }, payload) {
        commit(types.SET_GROUP_DATA, payload);
    },
    toggleFaqQuestion({ commit }, questionId) {
        commit(types.TOGGLE_FAQ_QUESTION, questionId);
    },
    addPostToFeed({ commit }, post) {
        commit(types.ADD_OR_UPDATE_POST_IN_FEED, post);
    },
    async deletePost({ commit }, { context, postId }) {
        await deletePost(context, postId);
        commit(types.REMOVE_POST_FROM_FEED, postId);
    },
    async editPost({ commit }, { context, post }) {
        const response = await editPost(context, post);
        commit(types.ADD_OR_UPDATE_POST_IN_FEED, response.data);
        return { post: response.data };
    },
    async reportPost({ commit }, { context, postId, reason, additional }) {
        await reportPost(context, postId, reason, additional);
        commit(types.SET_REPORTED_POST, postId);
    },

    async togglePinnedPost({ commit }, { context, postId, targetId }) {
        await togglePinnedPost(context, postId, targetId);
    },
    async createComment({ commit }, { context, comment }) {
        const response = await createComment(context, comment);
        commit(types.ADD_OR_UPDATE_COMMENT_IN_POST, response.data);
        return { comment: response.data };
    },
    async deleteComment({ commit }, { context, postId, commentId }) {
        await deleteComment(context, commentId);
        commit(types.REMOVE_COMMENT_FROM_POST, { commentId, postId });
    },
    async editComment({ commit }, { context, comment }) {
        const response = await editComment(context, comment);
        commit(types.ADD_OR_UPDATE_COMMENT_IN_POST, response.data);
        return { comment: response.data };
    },
    async reportComment({ commit }, { context, postId, commentId, reason, additional }) {
        await reportComment(context, commentId, reason, additional);
        commit(types.SET_REPORTED_COMMENT, { postId, commentId });
    },
    async toggleBestComment({ commit }, { context, postId, commentId }) {
        await toggleBestComment(context, commentId);
        commit(types.TOGGLE_BEST_COMMENT, { commentId, postId });
    },
};

const getters = {
    feedCanLoadMore: (state) => state.feed.canLoadMore,
    feedList: (state) => (state.feed.list !== undefined ? state.feed.list : []),
    feedProgress: (state) => (state.feed.progress !== undefined ? state.feed.progress : false),
    group: (state) => {
        return state.group;
    },
    leaveProgress: (state) => state.leaveProgress,
    joinProgress: (state) => state.joinProgress,
    faq: (state) => state.faq,
    faqTitle: (state) => (state.faq && state.faq.title && state.faq.title.trim() !== '' ? state.faq.title : 'FAQ'),
    faqProgress: (state) => state.faqProgress,
    faqError: (state) => state.faqError,
    hasPinnedPost: (state) => state.feed.list.some((item) => item.isPinned === true),
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
