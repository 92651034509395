import { handleAxiosError } from '@/utils/errorUtils';
import type { AxiosError } from 'axios';
import type { SentryEventProcessor } from './type';

/**
 * Processes axios errors and enriches them with more data.
 */
const axiosProcessor: SentryEventProcessor = (event, { originalException: error }) => {
    // aborts error reporting if the error was already handled
    if (handleAxiosError(error)) return null;

    /**
     * Because axios will trigger an Error when a request fails, the stack trace
     * is often going to be the same, grouping errors with different status codes
     * together in the same issue. We'll add more attributes to the event fingerprint
     * to have more granularity and separate different errors into different issues.
     * We are also adding more context information about the failed request.
     */
    if (typeof error === 'object' && error !== null && 'isAxiosError' in error) {
        const axiosException = error as AxiosError;
        /* eslint-disable-next-line no-param-reassign */
        event.fingerprint = [
            '{{ default }}',
            axiosException?.response?.status?.toString() ?? '',
            axiosException?.message,
        ];

        /* eslint-disable-next-line no-param-reassign */
        event.contexts = {
            response: {
                status_code: axiosException?.response?.status,
                request_url: axiosException?.request?.responseURL,
                request_data: JSON.stringify(axiosException?.config?.data) ?? '',
                response_data: JSON.stringify(axiosException?.response?.data) ?? '',
            },
        };
    }

    return event;
};

export default axiosProcessor;
