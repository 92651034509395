<template>
    <img
        :src="imgSrc"
        :style="{ background }"
    />
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from 'vue';
import type { ValuesOf } from '@/types/misc';
import { IllustrationBackground, type IllustrationType } from './Illustration';

/**
 * Component to easily integrate illustrations with or without a background.
 * It allows reusing the same illustration with different backgrounds.
 *
 * If you need a CSS-only approach to include an illustration as a background, try the `getIllustrationCssBackground` function.
 */
export default defineComponent({
    name: 'Illustration',
    props: {
        /**
         * The type of illustration to display.
         */
        type: {
            type: [String, undefined] as PropType<ValuesOf<typeof IllustrationType> | undefined>,
            default: undefined,
        },
        /**
         * If `type` is not provided, a custom image src can be used.
         */
        src: {
            type: String,
            default: undefined,
        },
        /**
         * Whether to display the illustration with a background.
         */
        withBackground: {
            type: Boolean,
            default: false,
        },
    },
    setup: (props) => ({
        background: computed(() => (props.withBackground ? IllustrationBackground : undefined)),
        imgSrc: computed(() => (props.type ? `/images/illustrations/${props.type}.svg` : props.src)),
    }),
});
</script>
