<template>
    <Modal
        :title="$t('modal.update.title')"
        title-class="text-center pl-11"
        class="modal-update"
        with-close-action
        stack-buttons
        no-cancel
        should-open-in-bottom-sheet
        @close="close"
    >
        <template #media>
            <Illustration
                type="gears"
                class="mx-auto"
                style="height: 296px"
            />
        </template>

        <p
            class="text-center font-body-2-regular text-content-secondary"
            v-text="$t('modal.update.description')"
        />

        <template #submit-action>
            <BtnCta
                :label="$t('modal.update.submit')"
                icon="reload"
                data-testid="modal-submit"
                @click="onSubmit"
            />
        </template>
    </Modal>
</template>

<script lang="ts">
import Modal from '@/components/Modal';
import { defineComponent } from 'vue';
import Illustration from '@/components/Illustration';
import modalMixin from '@/mixins/modalMixin';

/**
 * Modal to notify the user that an update is available.
 */
export default defineComponent({
    name: 'ModalUpdate',
    components: {
        Modal,
        Illustration,
    },
    extends: modalMixin,
    methods: {
        onSubmit() {
            window.location.reload();
        },
    },
});
</script>

<style lang="scss" scoped>
.modal-update {
    background:
        linear-gradient(180deg, transparent 0%, theme('colors.white') 75%),
        url('/images/backgrounds/brand-primary-blue.svg') no-repeat center center / cover;
}
</style>
