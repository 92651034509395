import { Semester, type SemesterResource } from '@/api/gateway/legacy-api/resources/SemesterResource';
import { i18n } from '@/plugins/i18n';

export function displaySemester(semester: SemesterResource): string {
    return semester.semester === Semester.Second
        ? i18n.t('semester.second', { year: semester.year })
        : i18n.t('semester.first', { year: `${Number(semester.year) - 1}/${semester.year}` });
}

export function displaySemestersList(semesters: SemesterResource[]) {
    return semesters.map((semester) => ({
        id: semester.id,
        year: semester.year,
        name: displaySemester(semester),
    }));
}

/**
 * The semester from the study has a slightly different format than
 * the semester resource, we need to convert it first
 */
export function displayStudySemester(studySemester: {
    id: number;
    description: string;
    year: string;
    is_winter: boolean;
}): string {
    const normalizedStudySemester = {
        id: studySemester.id,
        semester: studySemester.is_winter ? Semester.First : Semester.Second,
        year: studySemester.year as `${number}${number}${number}${number}`,
    } as SemesterResource;
    return displaySemester(normalizedStudySemester);
}

/**
 * The semester from the flashcards has yet a different format
 */
export function displayFlashcardSemester(studySemester: {
    id: number;
    isWinterSemester: boolean;
    name: string;
    year: string;
}): string {
    const normalizedStudySemester = {
        id: studySemester.id,
        semester: studySemester.isWinterSemester ? Semester.First : Semester.Second,
        year: studySemester.year as `${number}${number}${number}${number}`,
    } as SemesterResource;
    return displaySemester(normalizedStudySemester);
}
