export enum Semester {
    First = 0, // Winter/Autumn/Fall semester
    Second = 1, // Summer/Spring semester
}

export interface SemesterResource {
    id: number;
    semester: Semester;
    year: `${number}${number}${number}${number}`; // YYYY
}

export interface UserSemesterResource extends SemesterResource {
    description: string;
    display_field: string;
    display_name: string;
}
