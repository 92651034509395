import { retrieveFeed } from '@/api/backend/newsfeed';
import { injectXandrAds } from '@/utils/xandrUtils';
import {
    createComment,
    deleteComment,
    deletePost,
    editComment,
    editPost,
    getComments,
    reportComment,
    reportPost,
    toggleBestComment,
} from '@/api/backend/post';

const types = {
    ADD_OR_UPDATE_POST_IN_FEED: 'ADD_OR_UPDATE_POST_IN_FEED',
    ADD_OR_UPDATE_COMMENT_IN_POST: 'ADD_OR_UPDATE_COMMENT_IN_POST',
    PROPAGATE_IDENTITY_CHANGE: 'PROPAGATE_IDENTITY_CHANGE',
    REMOVE_POST_FROM_FEED: 'REMOVE_POST_FROM_FEED',
    REMOVE_COMMENT_FROM_POST: 'REMOVE_COMMENT_FROM_POST',
    SET_EXCLUDED_NOTIFICATIONS: 'SET_EXCLUDED_NOTIFICATIONS',
    SET_REPORTED_POST: 'SET_REPORTED_POST',
    SET_REPORTED_COMMENT: 'SET_REPORTED_COMMENT',
    TOGGLE_BEST_COMMENT: 'TOGGLE_BEST_COMMENT',
    UPDATE_CAN_LOAD_MORE_FEED: 'UPDATE_CAN_LOAD_MORE_FEED',
    UPDATE_FEED_LIST: 'UPDATE_FEED_LIST',
    UPDATE_FEED_LOADED: 'UPDATE_FEED_LOADED',
    UPDATE_FEED_PROGRESS: 'UPDATE_FEED_PROGRESS',
    UPDATE_POST_COMMENTS: 'UPDATE_POST_COMMENTS',
    UPDATE_POST_IN_FEED: 'UPDATE_POST_IN_FEED',
    PREPEND_POST: 'PREPEND_POST',
};

const state = {
    feed: {
        excludedNotifications: [],
        list: [],
        loaded: 0,
        progress: false,
        canLoadMore: true,
    },
};

const mutations = {
    [types.PROPAGATE_IDENTITY_CHANGE](state, { postId, user, isAnonymous }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        if (post.isOwner) {
            if (isAnonymous) {
                post.postDetails.anonymousUser = user;
                post.postDetails.user = null;
            } else {
                post.postDetails.user = user;
                post.postDetails.anonymousUser = null;
            }
            post.postDetails.isAnonymous = isAnonymous;
        }

        post.comments.forEach((comment) => {
            /* eslint-disable no-param-reassign */
            if (comment.isOwner) {
                if (isAnonymous) {
                    comment.anonymousUser = user;
                    comment.user = null;
                } else {
                    comment.user = user;
                    comment.anonymousUser = null;
                }
                comment.isAnonymous = isAnonymous;
            }
            /* eslint-enable no-param-reassign */
        });
    },
    [types.SET_EXCLUDED_NOTIFICATIONS](state, excludedNotifications = []) {
        // When this data is sent to the back end it will return an empty array in the response.
        // We need to store this only once and send always the same data to avoid receiving
        // the upload notifications again in the response.
        if (state.feed.excludedNotifications.length === 0) {
            state.feed.excludedNotifications = excludedNotifications;
        }
    },
    [types.SET_REPORTED_POST](state, postId) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        post.postDetails.isReported = true;
        post.postDetails.userReport = true;
    },
    [types.SET_REPORTED_COMMENT](state, { postId, commentId }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        const comment = post.comments.find((item) => {
            return item.id === commentId;
        });
        comment.isReported = true;
        comment.userReport = true;
    },
    [types.UPDATE_CAN_LOAD_MORE_FEED](state, canLoadMore = false) {
        state.feed.canLoadMore = canLoadMore;
    },
    [types.UPDATE_POST_IN_FEED](state, post) {
        const postIndex = state.feed.list.findIndex((feedItem) => {
            if (feedItem.type !== 'post') {
                return false;
            }

            const { context, id } = post.postDetails;

            return feedItem.postDetails.context === context && feedItem.postDetails.id === id;
        });

        if (postIndex === -1) {
            throw new Error('Post not found');
        }

        /* eslint-disable no-param-reassign */
        post.comments = state.feed.list[postIndex].comments;
        post.totalComments = state.feed.list[postIndex].totalComments;
        /* eslint-enable no-param-reassign */
        state.feed.list[postIndex] = post;
    },
    [types.UPDATE_FEED_LIST](state, data) {
        // add ab carousel slot for first batch of data
        if (!state.feed.list.length && data.length >= 5) {
            data.splice(5, 0, { type: 'ab_carousel' });
        }

        state.feed.list.push(...data);
    },
    [types.UPDATE_FEED_LOADED](state, loaded) {
        state.feed.loaded = loaded;
    },
    [types.UPDATE_FEED_PROGRESS](state, payload) {
        state.feed.progress = payload;
    },
    [types.UPDATE_POST_COMMENTS](state, { postId, context, comments = {} }) {
        const postIndex = state.feed.list.findIndex((feedItem) => {
            if (feedItem.type !== 'post') {
                return false;
            }

            const contextMatches = feedItem.postDetails.context === context;
            return contextMatches && feedItem.postDetails.id === postId;
        });

        if (postIndex === -1) {
            throw new Error('Post not found');
        }

        state.feed.list[postIndex].comments = comments;
    },
    [types.ADD_OR_UPDATE_POST_IN_FEED](state, post) {
        const postIndex = state.feed.list.findIndex((feedItem) => {
            if (feedItem.type !== 'post') {
                return false;
            }

            const contextMatches = feedItem.postDetails.context === post.postDetails.context;
            const idMatches = feedItem.postDetails.id === post.postDetails.id;

            return contextMatches && idMatches;
        });

        // if the post exists we just replace it with its updated version
        if (postIndex !== -1) {
            state.feed.list[postIndex] = post;
            return;
        }

        // if it's a new post we add it to the beginning of the feed
        state.feed.list.unshift(post);
    },
    [types.ADD_OR_UPDATE_COMMENT_IN_POST](state, comment) {
        const post = state.feed.list.find((feedItem) => {
            if (feedItem.type !== 'post') {
                return false;
            }

            const contextMatches = feedItem.postDetails.context === comment.context.type;
            return contextMatches && feedItem.postDetails.id === comment.context.id;
        });

        const commentIndex = post.comments.findIndex((postComment) => {
            return postComment.id === comment.id;
        });

        // if the comment exists we just replace it with its updated version
        if (commentIndex !== -1) {
            post.comments[commentIndex] = comment;
            return;
        }

        // if it's a new comment we push it to the post
        post.comments.push(comment);
        post.totalComments += 1;
    },
    [types.REMOVE_POST_FROM_FEED](state, { postId, context }) {
        const postIndex = state.feed.list.findIndex((feedItem) => {
            if (feedItem.type !== 'post') {
                return false;
            }

            const contextMatches = feedItem.postDetails.context === context;
            return contextMatches && feedItem.postDetails.id === postId;
        });

        if (postIndex === -1) {
            throw new Error('Post not found');
        }

        state.feed.list.splice(postIndex, 1);
    },
    [types.REMOVE_COMMENT_FROM_POST](state, { commentId, postId }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });
        const commentIndex = post.comments.findIndex((comment) => {
            return comment.id === commentId;
        });

        if (commentIndex === -1) {
            throw new Error('Comment not found');
        }

        post.comments.splice(commentIndex, 1);
    },
    [types.TOGGLE_BEST_COMMENT](state, { commentId, postId }) {
        const post = state.feed.list.find((feedItem) => {
            return feedItem.type === 'post' && feedItem.postDetails.id === postId;
        });

        if (post === undefined) {
            throw new Error('Post not found, cannot toggle best comment.');
        }

        const bestComment = post.comments.find((comment) => comment.id === commentId);

        if (bestComment === undefined) {
            throw new Error('Comment not found, cannot toggle best comment.');
        }

        post.postDetails.hasBestAnswer = !post.postDetails.hasBestAnswer;
        bestComment.isBest = !bestComment.isBest;
    },
    [types.PREPEND_POST](state, postData) {
        state.feed.list.unshift(postData);
    },
};

const actions = {
    propagateIdentityChange({ commit }, { postId, user, isAnonymous }) {
        commit(types.PROPAGATE_IDENTITY_CHANGE, {
            postId,
            user,
            isAnonymous,
        });
    },
    async loadPostComments({ commit }, { postId, context }) {
        const response = await getComments(context, postId);

        commit(types.UPDATE_POST_COMMENTS, {
            postId,
            context,
            comments: response.data.comments,
        });
    },
    retrieveFeed({ commit, state, rootGetters }) {
        commit(types.UPDATE_FEED_PROGRESS, true);

        return retrieveFeed(state.feed.loaded, state.feed.excludedNotifications)
            .then((response) => {
                const { data, meta } = response.data;
                commit(types.UPDATE_FEED_LIST, injectXandrAds(data || [], rootGetters['auth/isLoggedIn'], false));
                commit(types.UPDATE_CAN_LOAD_MORE_FEED, meta?.has_more ?? false);
                commit(types.UPDATE_FEED_LOADED, meta.loaded);
                commit(types.SET_EXCLUDED_NOTIFICATIONS, meta.excluded_notifications);
            })
            .finally(() => {
                commit(types.UPDATE_FEED_PROGRESS, false);
            });
    },
    async deletePost({ commit }, { context, postId }) {
        await deletePost(context, postId);
        commit(types.REMOVE_POST_FROM_FEED, { context, postId });
    },
    async editPost({ commit }, { context, post }) {
        const response = await editPost(context, post);
        commit(types.UPDATE_POST_IN_FEED, response.data);
        return { post: response.data };
    },
    async reportPost({ commit }, { context, postId, reason, additional }) {
        await reportPost(context, postId, reason, additional);
        commit(types.SET_REPORTED_POST, postId);
    },
    async createComment({ commit }, { context, comment }) {
        const response = await createComment(context, comment);
        commit(types.ADD_OR_UPDATE_COMMENT_IN_POST, response.data);
        return { comment: response.data };
    },
    deleteComment({ commit }, { context, postId, commentId }) {
        commit(types.UPDATE_FEED_PROGRESS, true);
        return deleteComment(context, commentId)
            .then(() => {
                commit(types.REMOVE_COMMENT_FROM_POST, { commentId, postId });
            })
            .finally(() => {
                commit(types.UPDATE_FEED_PROGRESS, false);
            });
    },
    async editComment({ commit }, { context, comment }) {
        const response = await editComment(context, comment);
        commit(types.ADD_OR_UPDATE_COMMENT_IN_POST, response.data);
        return { comment: response.data };
    },
    async reportComment({ commit }, { context, postId, commentId, reason, additional }) {
        await reportComment(context, commentId, reason, additional);
        commit(types.SET_REPORTED_COMMENT, { postId, commentId });
    },
    async toggleBestComment({ commit }, { context, postId, commentId }) {
        await toggleBestComment(context, commentId);
        commit(types.TOGGLE_BEST_COMMENT, { commentId, postId });
    },
    prependPost({ commit }, postData) {
        commit(types.PREPEND_POST, postData);
    },
    removePost({ commit }, { context, postId }) {
        commit(types.REMOVE_POST_FROM_FEED, { context, postId });
    },
};

const getters = {
    feedCanLoadMore: (state) => {
        return state.feed.canLoadMore;
    },
    feedList: (state) => {
        return state.feed.list || [];
    },
    feedProgress: (state) => state.feed?.progress || false,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
