<template>
    <component
        :is="isNativeProgress ? 'progress' : 'div'"
        v-resize.immediate="handleResize"
        :role="isNativeProgress ? undefined : 'progressbar'"
        :value="isNativeProgress ? sanitizedValue : undefined"
        :max="isNativeProgress ? max : undefined"
        :class="[
            'flex overflow-hidden progress-bar min-h-[0.125rem]',
            {
                'gap-x-0.5': variant == ProgressBarVariant.SEGMENTED && !segmentedProgressBarTooSmall,
                'indeterminate': variant == ProgressBarVariant.INDETERMINATE,
            }
        ]"
    >
        <template v-if="variant == ProgressBarVariant.SEGMENTED || variant == ProgressBarVariant.COMPOUND">
            <!--
                WARNING: Changing the gap between steps affects the 'minStepsWidth' calculation.
                Adjust the "marked-area" upon updating the gap.
            -->
            <div
                v-for="n in Math.floor(sanitizedMax)"
                :key="n"
                :style="{
                    'background-color': progressStepColor(n),
                }"
                class="flex-auto"
            ></div>
        </template>
    </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { type PropType } from 'vue';
import type { EnumToUnion } from '@/types/misc';
import { ProgressBarVariant, type ProgressBarCompoundValue } from './ProgressBar';

/**
 * Progress visual representation.
 */
export default defineComponent({
    name: 'ProgressBar',
    props: {
        /**
         * The progress bar variation.
         * Example: 'bar' or 'segmented'
         */
        variant: {
            type: String as PropType<EnumToUnion<ProgressBarVariant>>,
            default: ProgressBarVariant.BAR,
        },
        /**
         * The maximum value for the progress bar.
         */
        max: {
            type: [Number, String],
            default: 100,
        },
        /**
         * The state of the progress.
         */
        value: {
            type: [Number, String, Array] as PropType<number | string | ProgressBarCompoundValue[]>,
            default: 0,
        },
        /**
         * The minimum desired width of the steps in px. This has effect when progress bar variation is 'segmented' only.
         * The gap between steps will disappear if the width of the component is shrunk to the point where this minimum width can no longer be maintained.
         */
        minStepsWidth: {
            type: Number,
            default: 10,
        },
        /**
         * Sets the color of the progress bar. For example: primary or green-500
         */
        barColor: {
            type: String,
            default: 'primary',
        },
        /**
         * Sets the transition animation of the progress bar. For example: width 0.6s linear
         */
        transition: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            ProgressBarVariant,
            progressBarWidth: 0,
        };
    },
    computed: {
        isNativeProgress(): boolean {
            return this.variant === ProgressBarVariant.BAR;
        },
        sanitizedMax(): number {
            return Number(this.max);
        },
        sanitizedValue(): number {
            let value = 0;
            try {
                value =
                    this.variant === ProgressBarVariant.COMPOUND
                        ? (this.value as ProgressBarCompoundValue[])
                              .map((i) => i.value)
                              .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                        : Number(this.value as number | string);
            } catch {
                //
            }
            return Math.min(this.sanitizedMax, value);
        },
        /** progress in percentage */
        progress(): number {
            return (this.sanitizedValue / this.sanitizedMax) * 100;
        },
        segmentedProgressBarIntendedWidth() {
            const sw = this.sanitizedMax * this.minStepsWidth; // the width of steps combined in px
            const gw = (this.sanitizedMax - 1) * 2; // the gaps between steps in px <-- marked-area
            return sw + gw;
        },
        segmentedProgressBarTooSmall(): boolean {
            return this.progressBarWidth < this.segmentedProgressBarIntendedWidth;
        },
        progressBarColor(): string {
            return `rgb(var(--colors-${this.barColor})`;
        },
        progressStepColor() {
            return (step: number): string => {
                let color = this.progressBarColor;
                try {
                    if (this.variant === ProgressBarVariant.COMPOUND) {
                        let c = 0;
                        const compoundColor = (this.value as ProgressBarCompoundValue[]).find((i) => {
                            c += i.value;
                            return step <= c;
                        })?.color as string;
                        color = `rgb(var(--colors-${compoundColor})`;
                    }
                } catch {
                    //
                }
                return step <= Math.floor(this.sanitizedValue) ? color : 'rgb(var(--colors-slate-200)';
            };
        },
    },
    methods: {
        handleResize(target: Element): void {
            this.progressBarWidth = target.clientWidth;
        },
    },
});
</script>

<style lang="scss" scoped>
@keyframes indeterminate {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  30% {
    transform:  translateX(0) scaleX(0.3);
  }
  100% {
    transform:  translateX(100%) scaleX(0.6);
  }
}

/* Reset the default appearance */
progress {
    @apply w-full bg-transparent;
    
    appearance: none;

    /* Get rid of default border in Firefox. */
    border: none;
}

progress::-webkit-progress-inner-element {
    height: inherit;
}

progress::-webkit-progress-bar {
    @apply bg-transparent;
    height: inherit;
    transition: v-bind(transition);
}

progress::-moz-progress-bar {
    background-color: v-bind(progressBarColor);
    height: inherit;
}

progress::-webkit-progress-value {
    background-color: v-bind(progressBarColor);
    height: inherit;
    transition: v-bind(transition);
}

.progress-bar.indeterminate::before {
    content: '';
    height: inherit;
    width: 100%;
    background-color: v-bind(progressBarColor);
    animation: indeterminate 1.5s infinite linear;
    transform-origin: 0% 50%;
}
</style>
