import { openDB } from 'idb';

// Initialize IndexedDB with idb
const DB_NAME = 'TanstackQueryCache';
const STORE_NAME = 'queries';

const dbPromise = openDB(DB_NAME, 1, {
    upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
            db.createObjectStore(STORE_NAME);
        }
    },
});

// Define `idb` storage functions
export const idbStorage = {
    getItem: async <T = unknown>(key: string): Promise<T | null> => {
        const db = await dbPromise;
        const result = await db.get(STORE_NAME, key);
        return result as T | null;
    },
    setItem: async <T>(key: string, value: T): Promise<void> => {
        const db = await dbPromise;
        await db.put(STORE_NAME, value, key);
    },
    removeItem: async (key: string) => {
        const db = await dbPromise;
        await db.delete(STORE_NAME, key);
    },
    clear: async (): Promise<void> => {
        const db = await dbPromise;
        await db.clear(STORE_NAME);
    },
};
