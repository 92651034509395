import type { RouteRecordRaw } from 'vue-router';
import TheLayout from '@components/TheLayout';
import store from '@/store';
import { featureIsEnabled } from '@/modules/features';
import { builderGuard } from '@/views/BuilderPage';
import { isPreviewing } from '@builder.io/sdk-vue';
import { getPath } from './route';
import {
    aiChatGuard,
    authGuard,
    featureEnabledGuard,
    redirectIfLoggedInGuard,
    redirectIfLoggedInPupilGuard,
} from './guards';
import { dataGuard } from './guards/dataGuard';
import { RouteGroup } from './types';

const routes: RouteRecordRaw[] = [
    {
        path: '',
        component: TheLayout,
        props: {
            topBarPosition: 'fixed',
        },
        children: [
            featureIsEnabled('builderHomepage') || isPreviewing()
                ? {
                      path: getPath('home.index'),
                      alias: getPath('home.index_de'),
                      name: 'home',
                      redirect: {
                          name: 'builder',
                          replace: false,
                      },
                  }
                : {
                      path: getPath('home.index'),
                      alias: getPath('home.index_de'),
                      name: 'home',
                      component: () => import('@views/HomepageIndex'),
                      props: (route) => ({ intendedAction: route.query.intent }),
                      meta: {
                          group: RouteGroup.HOME,
                      },
                  },
        ],
    },
    {
        path: `/${getPath('signup.show')}`,
        name: 'signup.show',
        component: () => import('@views/AuthShow'),
    },
    {
        path: `/${getPath('login.show')}`,
        name: 'login.show',
        component: () => import('@views/AuthShow'),
    },
    {
        path: `/${getPath('profile.register')}`,
        name: 'profile.register',
        component: () => import('@views/ProfileRegister'),
    },
    {
        path: `/${getPath('auth.forgot_password.index')}`,
        name: 'auth.forgot-password',
        component: () => import('@views/ForgotPasswordShow.vue'),
    },
    {
        path: `/${getPath('auth.reset_password.index')}`,
        name: 'auth.reset-password',
        component: () => import('@views/ResetPasswordCreate.vue'),
    },
    {
        path: `/${getPath('auth.verification.user_verify')}`,
        name: 'auth.user_verify',
        component: () => import('@views/ReVerifyAndResetPassword.vue'),
    },
    {
        path: `/${getPath('auth.verification')}`,
        name: 'auth.verification',
        component: () => import('@views/EmailVerificationIndex.vue'),
    },
    {
        path: `/${getPath('unsubscribe.email.show')}`,
        name: 'unsubscribe.email.show',
        component: () => import('@views/UnsubscribeEmailShow'),
    },
    {
        path: '',
        component: TheLayout,
        children: [
            {
                path: getPath('company.show'),
                name: 'company.show',
                component: () => import('@views/CompanyShow'),
                meta: {
                    group: RouteGroup.COMPANY,
                },
            },
            {
                path: getPath('landing-page.show'),
                name: 'landing-page',
                component: () => import('@views/LandingPageIndex'),
            },
            {
                path: getPath('newsfeed.show'),
                name: 'newsfeed',
                component: () => import('@views/NewsfeedIndex'),
                props: (route) => ({ intendedAction: route.query.intent }),
                meta: {
                    group: RouteGroup.NEWSFEED,
                },
            },
            {
                path: getPath('dashboard.show'),
                name: 'dashboard',
                component: () => import('@views/Dashboard'),
                props: (route) => ({ intendedAction: route.query.intent }),
                meta: {
                    group: RouteGroup.NEWSFEED,
                },
            },
            {
                path: getPath('search'),
                name: 'search',
                component: () => import('@views/SearchIndex'),
                meta: {
                    group: RouteGroup.SEARCH,
                },
            },
            {
                path: 'search',
                redirect: 'en/search',
            },
            {
                path: 'suche',
                redirect: 'de/search',
            },
            {
                path: getPath('courses.posts.question'),
                name: 'post.course.show',
                component: () => import('@views/PostDetailIndex'),
                meta: {
                    group: RouteGroup.COURSE,
                },
            },
            {
                path: getPath('documents.posts.question'),
                name: 'post.document.show',
                component: () => import('@views/PostDetailIndex'),
                meta: {
                    group: RouteGroup.DOCUMENTS,
                },
            },
            {
                path: getPath('groups.posts.question'),
                name: 'post.group.show',
                component: () => import('@views/PostDetailIndex'),
                meta: {
                    group: RouteGroup.GROUP,
                },
            },
            {
                path: getPath('course.add'),
                name: 'course.add',
                component: () => import('@views/CourseIndex'),
                meta: {
                    group: RouteGroup.COURSE,
                },
            },
            {
                path: getPath('course.show'),
                name: 'course.show',
                component: () => import('@views/CourseShow'),
                meta: {
                    group: RouteGroup.COURSE,
                },
            },
            {
                path: getPath('document.universities.create'),
                name: 'document.universities.create',
                beforeEnter: [
                    authGuard,
                    redirectIfLoggedInPupilGuard('document.schools.create'),
                ],
                component: () => import('@views/UploadIndex'),
                props: { uploadType: 'university' },
                meta: {
                    group: RouteGroup.DOCUMENTS,
                },
            },
            {
                path: getPath('document.schools.create'),
                name: 'document.schools.create',
                beforeEnter: [authGuard],
                component: () => import('@views/UploadIndex'),
                props: { uploadType: 'school' },
                meta: {
                    group: RouteGroup.DOCUMENTS,
                },
            },
            {
                path: getPath('document.guests.create'),
                name: 'document.guests.create',
                beforeEnter: [
                    featureEnabledGuard('guestUploads'),
                    redirectIfLoggedInGuard('document.universities.create'),
                ],
                component: () => import('@views/UploadIndex'),
                props: { uploadType: 'universityGuest' },
                meta: {
                    group: RouteGroup.DOCUMENTS,
                },
            },
            {
                path: getPath('document.show'),
                name: 'document.show',
                component: () => import('@views/DocumentShow'),
                meta: {
                    group: RouteGroup.DOCUMENTS,
                },
            },
            {
                path: getPath('groups.index'),
                name: 'group.index',
                component: () => import('@views/GroupIndex'),
                meta: {
                    group: RouteGroup.GROUP,
                },
            },
            {
                path: getPath('groups.show'),
                name: 'group.show',
                component: () => import('@views/GroupShow'),
                meta: {
                    group: RouteGroup.GROUP,
                },
            },
            {
                path: getPath('universities.search'),
                name: 'university.index',
                component: () => import('@views/UniversityIndex'),
                meta: {
                    group: RouteGroup.UNIVERSITY,
                },
            },
            {
                path: getPath('university.show'),
                name: 'university.show',
                component: () => import('@views/UniversityShow'),
                meta: {
                    group: RouteGroup.UNIVERSITY,
                },
            },
            {
                path: getPath('company.index'),
                name: 'company.index',
                component: () => import('@views/CompanyIndex'),
                props: { isStandAlonePage: true },
                meta: {
                    group: RouteGroup.COMPANY,
                },
            },
            {
                path: ':lang/profile/:type(documents|flashcards)',
                name: 'profile.study-materials',
                component: () => import('@views/StudyMaterialIndex'),
            },
            {
                path: getPath('profile.edit'),
                name: 'profile.edit',
                component: () => import('@views/ProfileEdit'),
            },
            {
                path: getPath('profile.show'),
                name: 'profile.show',
                component: () => import('@views/ProfileShow'),
            },
            {
                path: getPath('auth.settings'),
                name: 'profile.settings',
                component: () => import('@views/SettingsEdit'),
            },
            {
                path: getPath('reward.list'),
                name: 'reward.index',
                component: () => import('@views/RewardIndex'),
                meta: {
                    group: RouteGroup.REWARDS,
                },
            },
            // AI Chat Routes
            {
                path: '/ai/chats/conversation/:id',
                name: 'ai-chats.conversation',
                beforeEnter: [authGuard, aiChatGuard],
                component: () => import('@views/AiChats'),
            },
            {
                path: getPath('ai-chats.index'),
                name: 'ai-chats.index',
                beforeEnter: [authGuard, aiChatGuard],
                component: () => import('@views/AiChats'),
            },
            {
                path: '/chats/user/:id',
                name: 'chats.user',
                beforeEnter: [authGuard],
                component: () => import('@views/ChatsIndex'),
            },
            {
                path: getPath('chats'),
                name: 'chats.index',
                beforeEnter: [authGuard],
                component: () => import('@views/ChatsIndex'),
            },
            {
                path: getPath('study-lists.index'),
                name: 'study-lists.index',
                beforeEnter: [
                    authGuard,
                    dataGuard(() => {
                        const loadingStudylists = store.dispatch('studylists/loadStudylists');
                        // if the study lists are already loaded, we don't need to wait for the promise
                        return store.getters['studylists/hasLoadedStudylists'] ? Promise.resolve() : loadingStudylists;
                    }),
                ],
                component: () => import('@views/StudyListsIndex'),
            },
            {
                path: getPath('career.corner.index'),
                name: 'career.corner.index',
                beforeEnter: [authGuard],
                component: () => import('@views/CareerCornerIndex'),
            },
            {
                path: getPath('subscription.shop.show'),
                name: 'subscription.shop.show',
                component: () => import('@views/SubscriptionShopShow'),
            },
            {
                path: getPath('subscription.success.show'),
                name: 'subscription.success.show',
                component: () => import('@views/SubscriptionSuccessShow'),
            },
            {
                path: getPath('subscription.status.show'),
                name: 'subscription.status.show',
                component: () => import('@views/SubscriptionStatusShow'),
            },
            {
                path: getPath('subscription.update-payment-method'),
                name: 'subscription.update-payment-method',
                beforeEnter: [authGuard],
                component: () => import('@views/SubscriptionUpdatePaymentMethod'),
            },
            {
                path: getPath('subscription.edit-payment-method'),
                name: 'subscription.edit-payment-method',
                beforeEnter: [authGuard],
                component: () => import('@views/SubscriptionEditPaymentMethod'),
            },
            {
                path: getPath('subscription.resubscribe.show'),
                name: 'subscription.resubscribe.show',
                component: () => import('@views/SubscriptionResubscribeShow'),
            },
            {
                path: `/${getPath('flashcards.sets.create')}`,
                name: 'flashcards.create',
                meta: {
                    group: RouteGroup.FLASHCARDS,
                    onlyLGScreen: true,
                },
                component: () => import('@views/FlashcardCreate'),
            },
            {
                path: `/${getPath('flashcards.edit.id')}`,
                name: 'flashcards.edit',
                meta: {
                    group: RouteGroup.FLASHCARDS,
                    onlyLGScreen: true,
                },
                component: () => import('@views/FlashcardEdit'),
            },
            {
                path: `/${getPath('flashcards.slug.id')}`,
                name: 'flashcards.show',
                meta: {
                    group: RouteGroup.FLASHCARDS,
                },
                component: () => import('@views/FlashcardShow'),
            },
            {
                path: `/${getPath('flashcards.study.slug.id')}`,
                name: 'flashcards.study',
                meta: {
                    group: RouteGroup.FLASHCARDS,
                },
                component: () => import('@views/FlashcardStudy'),
            },
        ],
    },
    {
        path: '',
        component: TheLayout,
        children: [
            /**
             * Builder.io route catch-all route. This route tries to match any undefined routes
             * and checks if builder.io has content for that path. If not it redirects to the error page.
             */
            {
                path: ':pathMatch(.*)*',
                name: 'builder',
                component: () => import('@/views/BuilderPage'),
                beforeEnter: [builderGuard],
            },
            /* 'NOT FOUND' MUST BE THE LAST ROUTE DEFINED TO CATCH THEM ALL! */
            {
                path: ':pathMatch(.*)*',
                name: 'not-found',
                redirect: {
                    name: 'error',
                    state: {
                        errorCode: '404',
                    },
                    replace: true,
                },
            },
            /*
             * ❗ IMPORTANT ❗
             * If you want to show a specific error page,
             * you need to provide the error code via the route state like:
             * `state: { errorCode: 404 }` (see the redirect of the `not-found` route above).
             *
             * vue-router changed the behavior how params are being passed to components.
             * Only params defined in the path are forwarded, any other are being discarded.
             * As we want to preserve the url for error pages, we cannot rely on the url,
             * so instead we use the history/route state.
             *
             * Read more about this change here: https://github.com/vuejs/router/blob/main/packages/router/CHANGELOG.md#414-2022-08-22
             */
            {
                path: ':pathMatch(.*)*',
                name: 'error',
                component: () => import('@views/ErrorIndex.vue'),
                beforeEnter: (to, from) => {
                    /* we need to redirect users to the new about us page */
                    if (to.path.match('about-us')) {
                        window.location.href = 'https://business.studydrive.net/about-us';
                        return false;
                    }

                    return true;
                },
                props: () => ({
                    errorCode: window.history.state.errorCode?.toString(),
                }),
            },
        ],
    },
    /* 'NOT FOUND' MUST BE THE LAST ROUTE DEFINED TO CATCH THEM ALL! */
];

export default routes;
