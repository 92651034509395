/**
 * Determines the state of a promise in a fast async way (as there is no sync way).
 * It races the promise against an already resolved value.
 * So any promise that is still pending will be determined quite fast.
 */
export function getPromiseState<T>(promise: PromiseLike<T>): Promise<'pending' | 'fulfilled' | 'rejected'> {
    const t = {};

    return Promise.race([promise, t]).then(
        (val) => (val === t ? 'pending' : 'fulfilled'),
        () => 'rejected',
    );
}

export function isPromise<T>(value: unknown): value is Promise<T> {
    return typeof value === 'object' && value !== null && 'then' in value;
}

/**
 * This type of Promise can be resolved or rejected outside of the promise constructor:
 * const deferred = new Deferred();
 * deferred.resolve() or deferred.reject()
 */
export class Deferred<T> {
    resolve!: (value: T | PromiseLike<T>) => void;

    reject!: (reason?: unknown) => void;

    then: Promise<T>['then'];

    catch: Promise<T>['catch'];

    finally: Promise<T>['finally'];

    constructor() {
        const p = new Promise<T>((res, rej) => {
            this.resolve = res;
            this.reject = rej;
        });
        this.then = p.then.bind(p);
        this.catch = p.catch.bind(p);
        this.finally = p.finally.bind(p);
    }
}
