import { readonly, ref } from "vue"
import { once } from "lodash-es"
import type { NavigationGuard } from "vue-router";

const isRouteLoading = ref(false);

/**
 * Guard to set the route loading state
 */
export const routeLoadingGuard = (val:  boolean): NavigationGuard => () => {
    isRouteLoading.value = val;
    return true;
};

/**
 * Provides a way to track whether any route is being loaded
 */
export const useRouteLoading = once(() => ({
    loading: readonly(isRouteLoading),
}))