import type { NavigationGuard } from 'vue-router';
import { queryClient } from '@/plugins/vue-query';
import { getBuilderPageContentQuery } from '@/queries/platformQueries';
import { dataGuard } from '@/router/guards/dataGuard';
import { isPreviewing } from '@builder.io/sdk-vue';
    
export { default } from './BuilderPage.vue';

/**
 * This guard is used to fetch the content for the builder page.
 * It will throw an error if the content is not available.
 */
export const builderGuard: NavigationGuard = dataGuard(
    async (t) => {
        if (isPreviewing()) return Promise.resolve();

        const data = await queryClient.fetchQuery({
            ...getBuilderPageContentQuery(t.path),
            retry: 0,
        });

        // throw error if content is not available with the same structure as builder errors
        if (!data) throw Object.assign(new Error('content not available'), { status: 404 });

        return data;
    },
    err => ({
        name: 'error',
        state: {
            errorCode: err && typeof err === 'object' && 'status' in err ? err.status as number : 500,
        },
        replace: true,
    })
)