import { captureException } from '@sentry/vue';
import { get, isEmpty } from 'lodash-es';
import { googleTokenLogin } from 'vue3-google-login';
import {
    sendEmailLoginRequest,
    sendEmailRegistrationRequest,
    sendFacebookLoginRequest,
    logFacebookAuthError,
    sendGoogleAuthRequest,
    sendAppleAuthRequest,
} from '@/api/backend/auth';
import {
    initializeFacebook,
    getLoginStatus as getFacebookLoginStatus,
    login as facebookLogin,
    logout as facebookLogout,
} from '@/modules/facebook';
import { initAuth as initAppleAuth } from '@/utils/auth/appleUtils';
import { openModalAsync } from '@plugins/modal';
import { i18n } from '@plugins/i18n';
import ModalEmailVerification from '@components/ModalEmailVerification';
import { EventBus, EventBusEvent } from '@/plugins/eventBus';

const types = {
    SET_AUTH_REQUEST_IN_PROGRESS: 'SET_AUTH_REQUEST_IN_PROGRESS',
    SET_FACEBOOK_AUTH_REQUEST_IN_PROGRESS: 'SET_FACEBOOK_AUTH_REQUEST_IN_PROGRESS',
    SET_AUTH_DETAILS: 'SET_AUTH_DETAILS',
    SET_AUTH_DETAILS_EXTRAS: 'SET_AUTH_DETAILS_EXTRAS',
    SET_AUTH_USER_VERIFIED: 'SET_AUTH_USER_VERIFIED',
    SET_FACEBOOK_EMAIL_PERMISSION_TRIES: 'SET_FACEBOOK_EMAIL_PERMISSION_TRIES',
    SET_FACEBOOK_LOGIN_STATUS: 'SET_FACEBOOK_LOGIN_STATUS',
    SET_GOOGLE_AUTH_REQUEST_IN_PROGRESS: 'SET_GOOGLE_AUTH_REQUEST_IN_PROGRESS',
    SET_APPLE_AUTH_REQUEST_IN_PROGRESS: 'SET_APPLE_AUTH_REQUEST_IN_PROGRESS',
};

// TODO: move most of this module out of vuex and into utils files and AuthForm.vue. AuthForm is the only component that uses most of the logic here.

const state = {
    user: get(window.sdWindow, 'user', null),
    roles: {
        superAdmin: 1,
        admin: 2,
        moderator: 3,
        user: 4,
        developer: 5,
        association: 6,
        organization: 7,
    },
    authRequestInProgress: false,
    authDetails: {
        extras: {},
    },
    facebook: {
        authRequestInProgress: false,
        emailPermissionAccessTries: 0,
        loginStatus: null,
    },
    google: {
        authRequestInProgress: false,
    },
    apple: {
        authRequestInProgress: false,
    },
};

const mutations = {
    [types.SET_AUTH_USER_VERIFIED](state) {
        if (!isEmpty(state.user) && state.user.is_verified === false) {
            state.user.is_verified = true;
        }
    },
    [types.SET_AUTH_REQUEST_IN_PROGRESS](state, payload) {
        state.authRequestInProgress = payload;
    },
    [types.SET_AUTH_DETAILS](state, payload) {
        state.authDetails = payload;
    },
    [types.SET_AUTH_DETAILS_EXTRAS](state, payload) {
        state.authDetails.extras = payload;
    },

    // Apple
    [types.SET_APPLE_AUTH_REQUEST_IN_PROGRESS](state, payload) {
        state.apple.authRequestInProgress = payload;
    },

    // Facebook
    [types.SET_FACEBOOK_EMAIL_PERMISSION_TRIES](state, payload) {
        state.facebook.emailPermissionAccessTries = payload;
    },
    [types.SET_FACEBOOK_LOGIN_STATUS](state, status) {
        state.facebook.loginStatus = status;
    },
    [types.SET_FACEBOOK_AUTH_REQUEST_IN_PROGRESS](state, payload) {
        state.facebook.authRequestInProgress = payload;
    },

    // Google
    [types.SET_GOOGLE_AUTH_REQUEST_IN_PROGRESS](state, payload) {
        state.google.authRequestInProgress = payload;
    },
};

const actions = {
    setAuthDetails({ commit }, payload) {
        commit(types.SET_AUTH_DETAILS, payload);
    },
    setAuthDetailsExtras({ commit }, payload) {
        commit(types.SET_AUTH_DETAILS_EXTRAS, payload);
    },
    verifyUser({ commit }) {
        commit(types.SET_AUTH_USER_VERIFIED);
    },
    facebookAuthRequestInProgress({ commit }, payload) {
        commit(types.SET_FACEBOOK_AUTH_REQUEST_IN_PROGRESS, payload);
    },
    async initializeAppleAuthentication({ rootState }) {
        await initAppleAuth(rootState.ui.locale);
    },
    async initializeFacebookAuthentication({ commit, rootGetters }, { statusCheck = true } = {}) {
        await initializeFacebook(rootGetters['ui/getLocale'] === 'de' ? 'de_DE' : 'en_US', statusCheck);

        if (!statusCheck) return undefined;

        // this looks a bit complicated, but we basically resolve the promise once we got the login status
        return new Promise((resolve) => {
            getFacebookLoginStatus((response) => {
                commit(types.SET_FACEBOOK_LOGIN_STATUS, response.status);
                resolve(response.status === 'connected');
            });
        });
    },
    sendEmailRegistrationRequest({ commit, getters }, payload) {
        if (getters.authRequestInProgress) {
            return Promise.resolve();
        }

        commit(types.SET_AUTH_REQUEST_IN_PROGRESS, true);

        return sendEmailRegistrationRequest({
            ...payload,
            extras: getters.authDetails.extras,
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response?.status === 419) {
                    // TODO: rethink this error handling and reuse our existing token refresh mechanism instead.
                    // In our horizon 2 POC, we have an even better token refresh system we should use ;)
                    window.location.reload(true);
                    return;
                }
                throw error;
            })
            .finally(() => {
                commit(types.SET_AUTH_REQUEST_IN_PROGRESS, false);
            });
    },
    sendEmailLoginRequest({ commit, getters }, payload) {
        if (getters.authRequestInProgress) {
            return Promise.resolve();
        }

        commit(types.SET_AUTH_REQUEST_IN_PROGRESS, true);

        return sendEmailLoginRequest({
            ...payload,
            extras: getters.authDetails.extras,
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response?.status === 419) {
                    // TODO: rethink this error handling and reuse our existing token refresh mechanism instead.
                    // In our horizon 2 POC, we have an even better token refresh system we should use ;)
                    window.location.reload(true);
                    return;
                }
                throw error;
            })
            .finally(() => {
                commit(types.SET_AUTH_REQUEST_IN_PROGRESS, false);
            });
    },
    handleFacebookAuthError({ dispatch }, response) {
        let errkey;
        let errMsg;

        switch (response.status) {
            case 'unknown':
                return;
            case 'not_authorized':
            case 'sd_email_permission_declined': {
                errkey = 'fbPermissionError';
                errMsg = i18n.t('authentication:facebook.permissionErrorLogin');
                break;
            }
            default: {
                errkey = response.error ?? 'fbGeneralError';
                errMsg = response.error ?? i18n.t('authentication:facebook.generalError');
                break;
            }
        }

        dispatch(
            'flashMessages/showError',
            {
                content: errMsg,
            },
            { root: true },
        );
        throw logFacebookAuthError(response);
    },
    sendFacebookAuthRequest({ commit, getters, dispatch }, payload) {
        if (getters.facebookAuthRequestInProgress) {
            return Promise.resolve();
        }

        commit(types.SET_FACEBOOK_AUTH_REQUEST_IN_PROGRESS, true);

        return new Promise((resolve) => {
            const callback = (response) => {
                if (response.status === 'connected') {
                    const { accessToken, grantedScopes } = response.authResponse;

                    const data = {
                        ...payload,
                        provider: 'facebook',
                        access_token: accessToken,
                    };

                    if (grantedScopes && grantedScopes.indexOf('email') === -1) {
                        // User approved the app but the email permission is missing
                        if (getters.facebookEmailPermissionAccessTries > 0) {
                            commit(types.SET_FACEBOOK_EMAIL_PERMISSION_TRIES, 0);
                            commit(types.SET_FACEBOOK_AUTH_REQUEST_IN_PROGRESS, false);

                            // We already asked for the email once, the user declined.
                            // Instead of showing the popup again, show the error.
                            return dispatch('handleFacebookAuthError', {
                                ...response,
                                status: 'sd_email_permission_declined',
                                action: payload.action,
                            });
                        }

                        // Ask the user for email permission.
                        commit(
                            types.SET_FACEBOOK_EMAIL_PERMISSION_TRIES,
                            getters.facebookEmailPermissionAccessTries + 1,
                        );
                        resolve(facebookLogin(callback, 'email'));
                    } else {
                        commit(types.SET_FACEBOOK_AUTH_REQUEST_IN_PROGRESS, false);
                        resolve(dispatch('loginFacebookUser', data));
                    }
                } else {
                    commit(types.SET_FACEBOOK_AUTH_REQUEST_IN_PROGRESS, false);
                    resolve(dispatch('handleFacebookAuthError', { ...response, action: payload.action }));
                }
                return null;
            };

            facebookLogin(callback);
        }).catch((err) => {
            commit(types.SET_FACEBOOK_AUTH_REQUEST_IN_PROGRESS, false);
            throw err;
        });
    },
    loginFacebookUser({ commit, dispatch }, payload) {
        // we need to consider that the registration form can be used to login and the login form to register with facebook
        return sendFacebookLoginRequest(payload)
            .then((response) => {
                if (!response.data || response.data.ssoFail) {
                    // General error or SSO server fail
                    captureException(logFacebookAuthError(response.data));
                    // fail silently - since its an internal server error
                    window.location.reload(true);
                    return Promise.reject();
                }

                if (!response.data?.success) {
                    return dispatch('handleFacebookAuthError', { ...response.data, action: payload.action });
                }

                return response.data;
            })
            .finally(() => {
                commit(types.SET_FACEBOOK_AUTH_REQUEST_IN_PROGRESS, false);
            });
    },
    async showEmailVerificationPopup({ getters, dispatch }, { closeable }) {
        if (getters.isVerified) {
            return true;
        }

        const result = await openModalAsync(ModalEmailVerification, { closeable });

        if (result) {
            return dispatch('verifyUser');
        }
        return true;
    },
    async loginGoogleUser({ commit }, payload) {
        commit(types.SET_GOOGLE_AUTH_REQUEST_IN_PROGRESS, true);
        try {
            // TODO: adjust when updating the google auth package
            const googleUser = await googleTokenLogin({
                clientId: sdWindow.googleClientId,
            });
            if (!googleUser) {
                commit(types.SET_GOOGLE_AUTH_REQUEST_IN_PROGRESS, false);
                return Promise.resolve();
            }

            const response = await sendGoogleAuthRequest({
                ...payload,
                access_token: googleUser.access_token,
                provider: 'google',
            });

            commit(types.SET_GOOGLE_AUTH_REQUEST_IN_PROGRESS, false);
            return response.data;
        } catch (error) {
            commit(types.SET_GOOGLE_AUTH_REQUEST_IN_PROGRESS, false);

            if (!error) throw new Error('Google login is not available');

            if (error.error === 'popup_closed_by_user') return Promise.resolve();

            throw error;
        }
    },
    async loginAppleUser({ commit }, payload) {
        commit(types.SET_APPLE_AUTH_REQUEST_IN_PROGRESS, true);

        try {
            if (!window.AppleID?.auth) {
                throw new Error('Apple login is not available');
            }
            // check the docs here https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
            const appleData = await window.AppleID.auth.signIn();

            const response = await sendAppleAuthRequest({
                ...payload,
                provider: 'apple',
                access_token: appleData.authorization.id_token,
                first_name: appleData.user?.name?.firstName,
                last_name: appleData.user?.name?.lastName,
            });
            commit(types.SET_APPLE_AUTH_REQUEST_IN_PROGRESS, false);
            return response.data;
        } catch (err) {
            commit(types.SET_APPLE_AUTH_REQUEST_IN_PROGRESS, false);

            if (err.error === 'popup_closed_by_user') return Promise.resolve();

            throw err;
        }
    },
    async logout({ state, dispatch }) {
        EventBus.emit(EventBusEvent.CLEAR_USER_DATA);

        if (state.facebook.loginStatus === 'connected') {
            // we need to initialize the facebook sdk before we can log out users.
            await dispatch('initializeFacebookAuthentication');
            facebookLogout(() => {
                window.location.href = '/logout';
            });
        } else {
            window.location.href = '/logout';
        }
    },
};

const getters = {
    user: (state) => state.user,
    userPicture: (state) => {
        return state.user ? (state.user.image.id ? state.user.image.url_big : state.user.image.avatar.url_big) : '';
    },
    isLoggedIn: (state) => !isEmpty(state.user),
    isVerified: (state) => state.user && state.user.is_verified,
    isAdmin(state) {
        return (
            state.user &&
            (state.user.role_id === state.roles.superAdmin ||
                state.user.role_id === state.roles.admin ||
                state.user.role_id === state.roles.moderator ||
                state.user.role_id === state.roles.developer)
        );
    },
    authDetails: (state) => state.authDetails,
    facebookEmailPermissionAccessTries: (state) => state.facebook.emailPermissionAccessTries,
    authRequestInProgress: (state) => state.authRequestInProgress,
    facebookAuthRequestInProgress: (state) => state.facebook.authRequestInProgress,
    appleAuthRequestInProgress: (state) => state.apple.appleAuthRequestInProgress,
    googleAuthRequestInProgress: (state) => state.google.authRequestInProgress,
    isAuthInProgress: (state) =>
        state.authRequestInProgress ||
        state.facebook.authRequestInProgress ||
        state.google.authRequestInProgress ||
        state.apple.authRequestInProgress,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
