import type { Plugin } from 'vue';
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query';
import { experimental_createPersister } from '@tanstack/query-persist-client-core';
import { idbStorage } from '@/databases/TanStackQueryCacheIDB';

export const QUERY_KEYS = {
    AI_CHAT_STARTER_PROMPTS: 'ai-chat-starter-prompts',
    SEMESTERS: 'semesters',
    BUILDER_CONTENT: 'builder-content',
} as const;

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5, // 5 minutes
            retry: 2,
            refetchOnWindowFocus: false,
        },
    },
});

/**
 * Persister responsible for caching query results to IndexedDB.
 * It allows query data to persist across page reloads and browser sessions.
 *
 * Note: This is using the experimental persister API from TanStack Query.
 * Future updates might change the implementation details.
 * @link https://tanstack.com/query/v5/docs/framework/vue/plugins/createPersister
 */
export const persister = experimental_createPersister({
    storage: idbStorage,
});

export const clearTanstackCache = async () => {
    await idbStorage.clear(); // Clear IndexedDB cache
    queryClient.clear(); // Clear in-memory cache
};

export default {
    install(app) {
        app.use(VueQueryPlugin, { queryClient, enableDevtoolsV6Plugin: true });
    },
} as Plugin;
