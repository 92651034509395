export enum ProgressBarVariant {
    BAR = 'bar',
    INDETERMINATE = 'indeterminate',
    SEGMENTED = 'segmented',
    COMPOUND = 'compound',
}

export type ProgressBarCompoundValue = {
    value: number;
    color: string;
};
