/* eslint-disable no-param-reassign */
import { isAxiosError } from 'axios';
import type { NavigationGuard, RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { isPromise } from '@/helpers/promise';

export type RouteData<T> = T[] | Record<string, T>;
export type RouteDataGetter<T> = (to: RouteLocationNormalized) => Promise<T> | Array<Promise<T>> | Record<string, Promise<T>>;
export type RouteDataErrorHandler = (e: Error | unknown) => RouteLocationRaw;

export interface RouteDataMeta<T> {
    data?: RouteData<T>;
    keys: string[] | undefined;
    resolver: Promise<T[]>;
    getter: RouteDataGetter<T>;
    errorHandler: RouteDataErrorHandler;
}

declare module 'vue-router' {
    interface RouteMeta {
        data?: RouteDataMeta<unknown>;
    }
}

function dataGuardErrorHandler(e: Error | unknown) {
    if (e instanceof Error && isAxiosError(e)) {
        return {
            name: 'error',
            replace: true,
            state: { errorCode: e.response?.status?.toString() },
        };
    }

    throw e;
}

/**
 * Guard to pre-load data for a route.
 * This allows us to load a route component and its data simultaneously, speeding up page-loads.
 * 
 * It will attach the provided promises from the getter function to the route.
 * The promises will then be resolved by the `resolveDataGuard` at the last moment before the route is initialized.
 * The route resolution will also be delayed until all promises are resolved (see `resolveDataGuard`).
 * 
 * With the onError handler, you can customize where the route should be redirected in case of an error.
 */
export const dataGuard = <T>(
    getter: RouteDataGetter<T>,
    onError: RouteDataErrorHandler = dataGuardErrorHandler
): NavigationGuard => to => {
    const structure = getter(to);
    const isArr = Array.isArray(structure);
    const isP = isPromise(structure);
    const resolver = Promise.all(isArr ? structure : (isP ? [structure] : Object.values(structure)));

    to.meta.data = {
        keys: isArr || isP ? undefined : Object.keys(structure),
        resolver,
        getter,
        errorHandler: onError,
    };

    return true;
};

/**
 * Resolves the promises from the `dataGuard` and attaches the data to the route.
 * It will reconstruct the structure as it was provided to the `dataGuard`.
 * 
 * This guard should only run once in the global router scope.
 */
export const resolveDataGuard: NavigationGuard = async (to) => {
    try {
        if (to.meta.data) {
            const data = await to.meta.data.resolver;
            const {keys} = to.meta.data;
            const reconstructedData = keys ? Object.fromEntries(keys.map((key, index) => [key, data[index]])) : data;
            to.meta.data.data = reconstructedData;
        }
        return true;
    } catch (e) {
        return to.meta.data?.errorHandler?.(e);
    }
};
