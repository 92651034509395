import { once } from 'lodash-es';
import type { ValuesOf } from '@/types/misc';

export const IllustrationType = Object.freeze({
    ADD: 'add',
    AI: 'ai',
    ANNOUNCEMENT: 'announcement',
    BRAIN: 'brain',
    BRIEFCASE: 'briefcase',
    BULLSEYE: 'bullseye',
    CAREER_CORNER: 'career-corner',
    CHART: 'chart',
    COINS: 'coins',
    COMMUNICATION: 'communication',
    DOWNLOAD_FOLDER: 'download-folder',
    DOWNLOAD_PHONE: 'download-phone',
    GEARS: 'gears',
    HANDMADE: 'handmade',
    HAT: 'hat',
    HEADPHONES: 'headphones',
    HOME_OFFICE: 'home-office',
    HOUSE: 'house',
    ID: 'id',
    INCOGNITO: 'incognito',
    INVITE_FRIENDS: 'invite-friends',
    LAPTOP: 'laptop',
    MAGNIFYING_GLASS: 'magnifying-glass',
    STAIRS: 'stairs',
    STILL_LIFE: 'still-life',
    SURPRISE: 'surprise',
    THANK_YOU: 'thank-you',
    TROPHY: 'trophy',
    TROPHY_LAPTOP: 'trophy-laptop',
    TROPHY_PHONE: 'trophy-phone',
    UPLOAD_LAPTOP: 'upload-laptop',
    UPLOAD_PHONE: 'upload-phone',
    UPLOAD_TROPHY: 'upload-trophy',
});

export const IllustrationBackground = 'radial-gradient(circle, #FFE5E7 59%, transparent 59%)';

export const getIllustrationCssBackground = once(
    (type: ValuesOf<typeof IllustrationType>, background: boolean = false) => {
        // same as in Illustration.vue
        const bg = background ? `, ${IllustrationBackground}` : '';
        return `url(/images/illustrations/${type}.svg) center center / contain no-repeat${bg}`;
    },
);
