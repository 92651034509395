import type { RouteLocationNamedRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { languageCode } from '@/plugins/i18n';
import xandr from '@/modules/xandr';
import { routeLoadingGuard } from '@/composables/routeLoading';
import routes from './routes';
import { langGuard, pageStatusGuard } from './guards';
import { resolveDataGuard } from './guards/dataGuard';

declare module 'vue-router' {
    interface Router {
        /**
         * Resolves the route with default parameters (like :lang).
         * This is a computed property that will be updated when the default params change.
         */
        resolveWithDefaults: (to: RouteLocationNamedRaw) => ComputedRef<RouteLocation & { href: string }>;
    }
}

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// this should be the first guard to run
router.beforeEach(routeLoadingGuard(true));
router.beforeEach(langGuard);
router.beforeEach(pageStatusGuard);

router.beforeResolve(resolveDataGuard);
// this should be the last guard to run
router.beforeResolve(routeLoadingGuard(false));

// inject xandr adlib
// TODO: as not every route change refreshes the page,
// this call will create new duplicated event listeners.
// We need to clean up these events or handle the events differently
router.afterEach(xandr);

router.resolveWithDefaults = (to: RouteLocationNamedRaw) =>
    computed(() =>
        router.resolve(
            Object.assign(to, {
                params: {
                    lang: languageCode.value,
                    ...(to.params ?? {}),
                },
            }),
        ),
    );

// setting the router to use in vuex store files
window.router = router;
export default router;
